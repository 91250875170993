function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

$(document).ready(function(){

  var isSending = false;
  var langselector = $('#langselector');
  var navarr = $('#navarr');
  var navmobile = $('#nav-mobile');

  $('#langnav').on('click', function(e){
    e.preventDefault();

    if(langselector.hasClass('nav-hidden')){
      navmobile.removeClass('expanded');
      langselector.removeClass('nav-hidden');
      navarr.addClass('expanded');
      return;;
    }

    langselector.addClass('nav-hidden');
    navarr.removeClass('expanded');
  });

  $('#nav-mobile-open').on('click', function(e){
    e.preventDefault();
    navmobile.addClass('expanded');
    langselector.addClass('nav-hidden');
    navarr.removeClass('expanded');
  });

  $('#nav-mobile-close').on('click', function(e){
    e.preventDefault();
    navmobile.removeClass('expanded');
  });

  $('.contact-option').on('click', function(e){
    e.preventDefault();
    $('.contact-option').removeClass('active');
    $(this).addClass('active');
  })

  $('#form-send').on('click', function(e){    
    e.preventDefault();

    if(isSending){
      return;
    }

    $('#cta-form-arrow').addClass('loading');

    var name = $('#cform-name').val() || null;
    var email = $('#cform-email').val() || null;
    var phone = $('#cform-phone').val() || null;
    var message = $('#cform-message').val() || '';
    var source = window.location.href || 'https://winexfood.com'
    var type = 'Other';

    if($('*[data-coption="PRO"]').hasClass('active')){
      type = "PRO";
    }
    
    if($('*[data-coption="Amateur"]').hasClass('active')){
      type = "Amateur";
    }

    if($('*[data-coption="Company"]').hasClass('active')){
      type = "Company";
    }

    if(!name){
      alert("Please enter your name");
      return;
    }

    if(!email && !phone){
      alert("Please enter your email or phone");
      return;
    }

    if(email){
      if(!isEmail(email)){
        alert("Invalid format for email, please review");
        return;
      }
    }

    isSending = true;

    $.post(_contactsAPI + "/leads/create", {
      name: name,
      email: email,
      phone: phone,
      message: message,
      type: type,
      source: source,
    }).done(function(){
      isSending = false;
      MicroModal.show('modal-form-success');
      $('#cta-form-arrow').removeClass('loading');
    }).fail(function(){
      isSending = false;
      MicroModal.show('modal-form-error');
      $('#cta-form-arrow').removeClass('loading');
    });

    return;

  });

  $('#conset-cookie-trigger').on('click', function(){
    $.get("/policy-conset");
    MicroModal.close("modal-conset");
  });

  MicroModal.init();

  if(!getCookie('cookie-conset')){
    MicroModal.show('modal-conset');
  }


});